import React from 'react';
import StaticLayout from 'components/layouts/static-layout';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import {ColorBox, Container, P, Wave} from 'components/wrappers';
import {HEADER_BG_COLOR, HERO_BG_COLOR, HERO_COLOR} from 'assets/theme';

const About = ({data, location}) => {
  const {title, siteUrl} = data.site.siteMetadata;
  return (
    <StaticLayout
      {...{
        bgColor: HERO_BG_COLOR,
        location,
        seo: {title: `${title} about`, description: `About page for ${siteUrl}`},
      }}
    >
      <ColorBox bgColor={HERO_BG_COLOR} color={HERO_COLOR}>
        <Container hero>
          <h1>About</h1>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} />
      <ColorBox bgColor={HEADER_BG_COLOR} color={HERO_COLOR}>
        <Container heroPadding>
          <P l>
            Hello, my name is Paul, and I am a web developer based in Milton Keynes.
          </P>
          <P l>
            For the past 5 years, I have contracted out as an IT consultant to a wide
            range of companies large and small, and across different industries. These
            include Apple, Global Travel Ventures, Paymentsense, Currencies Direct,
            Woodford Asset Management, and Santander.
          </P>
          <P l>
            I created bearjs.co.uk as in addition to consultancy I wanted to to work with
            small & medium sized enterprises (SMEs) companies on a freelance basis. My aim
            is to help SMEs leverage web technologies for greater efficiency (increased
            productivity, reduced costs, better allocation of resources).
          </P>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} flip />
      <br />
    </StaticLayout>
  );
};

About.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default About;

export const GATSBY_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
